/* eslint-disable @typescript-eslint/indent */
import { useState, FC } from 'react';

import type {
  Block,
  Input,
  Text,
} from 'src/__generated__/graphqlTypes';

import FeedbackForm from './FeedbackForm';

import type { FeedbackFormInterfaceStateValues, FeedbackFormContainerInterface } from '../config/declarations';
import { getComponents, getInitialValues } from '../lib';
import { useHandlers } from '../lib/hooks';

const FeedbackFormContainer: FC<FeedbackFormContainerInterface> = ({ feedbackForm }) => {
  const inputs = (feedbackForm.contentTypesCollection?.items as Array<Input>) || [];
  const { link, title, richTitle } = feedbackForm;
  const components = getComponents(inputs);
  const initialInputValues = getInitialValues(components);
  const requestTypeOptions = components.requestTypeInput
    .optionsCollection?.items as Array<Text> || [];

  const [isModalVisible, setModalVisibilityState] = useState<boolean>(false);
  const [
    inputValues,
    setInputValues,
  ] = useState<FeedbackFormInterfaceStateValues>(initialInputValues);

  const clearForm = () => setInputValues(initialInputValues);
  const closeModal = () => setModalVisibilityState(false);
  const showModal = () => setModalVisibilityState(true);
  const submit = useHandlers({ showModal, clearForm });

  return (
    <FeedbackForm
      inputValues={inputValues}
      setInputValues={setInputValues}
      handleSubmitForm={submit}
      feedbackForm={feedbackForm}
      modal={components.modal as Block}
      isModalVisible={isModalVisible}
      closeModal={closeModal}
      button={link!}
      title={title!}
      richTitle={richTitle}
      requestTypeOptions={requestTypeOptions}
    />
  );
};

export default FeedbackFormContainer;
