import { FC } from 'react';
import { useSelector } from 'react-redux';

import { SectionComponentInterface } from 'types/componentTypes';
import { HelpCenter } from 'components/Sections/ContactUsHelpCenter';
import { Block } from 'src/__generated__/graphqlTypes';
import { Store } from 'app-redux/types/storeTypes';
import { setItemInObjectBySlug } from 'lib/sharedMethods.service';
import { HelpCenterContainerSlug as Slug } from 'components/Sections/ContactUsHelpCenter/declarations';

const helpCenterSlugs = {
  'help-center-title': Slug.TITLE,
  'general-info': Slug.GENERAL_INFO,
  newsletters: Slug.NEWSLETTERS,
  'help-charge-info': Slug.CHARGE_INFO,
};

const HelpCenterContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const isMobileViewWidth = useSelector((store: Store) => store.server.app.isMobileViewWidth);
  const slug = useSelector((store: Store) => store.server.page.slug);
  const {
    mobileViewMaxWidth,
    contactUsTabletMaxWidth,
  } = useSelector((store: Store) => store.server.app.scssVariables);

  if (!blocks?.length) {
    return null;
  }

  const reducer = setItemInObjectBySlug(helpCenterSlugs);
  const {
    title,
    generalInfo,
    newsletters,
    chargeInfo,
  } = blocks.reduce(reducer, {} as Record<Slug, Block>);

  if (!title || !generalInfo) {
    return null;
  }

  return (
    <HelpCenter
      title={title}
      slug={slug}
      generalInfo={generalInfo}
      newsletters={newsletters}
      chargeInfo={chargeInfo}
      bgColor={bgColor}
      contactUsTabletMaxWidth={contactUsTabletMaxWidth}
      isMobileViewWidth={isMobileViewWidth}
      mobileViewMaxWidth={mobileViewMaxWidth}
    />
  );
};

export default HelpCenterContainer;
