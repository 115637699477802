import {
  BLOCKS,
  MARKS,
  INLINES,
} from '@contentful/rich-text-types';
import { FC } from 'react';

import { RichTextParsersConfig } from 'types/objectTypes';
import { mapBlocksHeadingWithId } from 'lib/richTextMappers';
import styles from 'components/Sections/ContactUsGetInTouch/GetInTouch.module.scss';
import classes from 'src/styles/_commonClasses.module.scss';
import { CommonRichText } from 'components/Shared/SharedComponents';
import { ContactUsGetInTouchInterface, TitleInterface } from 'components/Sections/ContactUsGetInTouch/declarations';
import { FeedbackForm } from 'entities/FeedbackForm';

import ContactButtons from './ContactButtons';

const getParsersConfig = (id: string = ''): RichTextParsersConfig => ({
  [BLOCKS.HEADING_1]: {
    classNames: styles.getInTouchTitleHeading,
    mapper: mapBlocksHeadingWithId(id!),
  },
  [BLOCKS.PARAGRAPH]: {
    classNames: styles.getInTouchTitleParagraph,
  },
  [MARKS.BOLD]: {
    classNames: classes.textBold,
  },
  [INLINES.HYPERLINK]: {
    classNames: styles.getInTouchTitleParagraphLink,
  },
});

/* Nested component */
const Title: FC<TitleInterface> = ({ title, content, id }) => {
  if (!title && !content) {
    return null;
  }

  return (
    <div className={styles.getInTouchTitle}>
      {title && <CommonRichText content={title} parsersConfig={getParsersConfig(id!)} />}
      {content && <CommonRichText content={content} parsersConfig={getParsersConfig(id!)} />}
    </div>
  );
};

/* Main component */
const GetInTouch: FC<ContactUsGetInTouchInterface> = ({
  title,
  contactButton,
  feedbackForm,
  bgColor,
  closeFeedbackForm,
  isFeedBackVisible,
  showFeedbackForm,
  ctaCallEvent,
}) => {
  const { content, richTitle, blockId } = title;
  const { textList } = feedbackForm;

  return (
    <section className={styles.getInTouchBorderBottom}>
      {!isFeedBackVisible
        ? (
          <div className={styles.getInTouch} style={{ background: bgColor! }}>
            <Title
              title={richTitle!}
              content={content!}
              id={blockId}
            />
            <div className={styles.getInTouchButtons}>
              <ContactButtons
                block={contactButton}
                modal={feedbackForm}
                isFeedBackVisible={isFeedBackVisible}
                showFeedbackForm={showFeedbackForm}
                ctaCallEvent={ctaCallEvent}
              />
            </div>
          </div>
        ) : (
          <div className={styles.getInTouchFeedback}>
            <div className={styles.getInTouchCloseModal}>
              <button onClick={closeFeedbackForm} type="button">
                <div className={styles.getInTouchCloseModalCarot}>
                  {' '}
                  {'<'}
                  {' '}
                </div>
                {textList ? textList[0] : ''}
              </button>
            </div>
            <div className={styles.getInTouchFeedbackEmail}>
              <FeedbackForm feedbackForm={feedbackForm} />
            </div>
          </div>
        )}
    </section>

  );
};
export default GetInTouch;
