import {
  Block,
  BlockContent,
  BlockRichTitle,
} from 'src/__generated__/graphqlTypes';
import { OptionalBgColorInterface } from 'types/componentTypes';

export interface ContactUsGetInTouchInterface extends OptionalBgColorInterface {
  title: Block;
  contactButton: Block;
  feedbackForm: Block;
  isMobileViewWidth: boolean;
  mobileViewMaxWidth: string;
  isFeedBackVisible: boolean;
  closeFeedbackForm: () => void;
  showFeedbackForm: () => void;
  ctaCallEvent: () => void;
}

export enum GetInTouchContainerSlug {
  TITLE = 'title',
  FEEDBACK_FORM ='feedbackForm',
  CONTACT_BUTTON ='contactButton',
}

export interface TitleInterface {
  title?: BlockRichTitle;
  content?: BlockContent;
  id: Block['blockId'];
}
