import { FC } from 'react';

import { ContactButtonsInterface } from 'components/Sections/ContactUsGetInTouch/ContactButtons/declarations';
import { Link } from 'src/__generated__/graphqlTypes';

import { ButtonImage } from './ButtonImage';

const ContactButtons: FC<ContactButtonsInterface> = ({
  links,
  showFeedbackForm,
  ctaCallEvent,
}) => (
  <>
    { links.map((item: Link) => (
      <ButtonImage
        key={item.slug} block={item}
        showFeedBack={showFeedbackForm}
        ctaCallEvent={ctaCallEvent}
      />
    )) }
  </>
);
export default ContactButtons;
