export enum InputSlug {
  EMAIL_INPUT = 'emailInput',
  REQUEST_TYPE_INPUT = 'requestTypeInput',
  MESSAGE_INPUT = 'messageInput',
  MODAL = 'modal',
}

export const feedbackFormSlugs = {
  'email-input': InputSlug.EMAIL_INPUT,
  'request-type-input': InputSlug.REQUEST_TYPE_INPUT,
  'message-input': InputSlug.MESSAGE_INPUT,
  modal: InputSlug.MODAL,
};

export enum FeedbackFormStateValue {
  EMAIL = 'email',
  REQUEST_TYPE = 'requestType',
  MESSAGE = 'message',
}
