import { useState, FC } from 'react';
import { useSelector } from 'react-redux';

import { SectionComponentInterface } from 'types/componentTypes';
import { Block } from 'src/__generated__/graphqlTypes';
import { Store } from 'app-redux/types/storeTypes';
import GetInTouch from 'components/Sections/ContactUsGetInTouch/GetInTouch';
import { GetInTouchContainerSlug as Slug } from 'components/Sections/ContactUsGetInTouch/declarations';

const getInTouchSlugs = {
  'get-in-touch': Slug.TITLE,
  'contact-buttons': Slug.CONTACT_BUTTON,
  'feedback-form': Slug.FEEDBACK_FORM,
};

const GetInTouchContainer: FC<SectionComponentInterface> = ({
  blocks,
  bgColor,
}) => {
  const [isFeedBackVisible, setFeedBackFormVisibilityState] = useState<boolean>(false);

  const isMobileViewWidth = useSelector(
    (store: Store) => store.server.app.isMobileViewWidth,
  );
  const { mobileViewMaxWidth } = useSelector(
    (store: Store) => store.server.app.scssVariables,
  );

  if (!blocks?.length) {
    return null;
  }

  const {
    title,
    contactButton,
    feedbackForm,
  } = blocks.reduce(
    (accumulator, item) => {
      const fieldName = getInTouchSlugs[item.slug || ''];

      if (fieldName) {
        accumulator[fieldName] = item;
      }

      return accumulator;
    },
    {} as Record<Slug, Block>,
  );

  const closeFeedbackModal = () => {
    setFeedBackFormVisibilityState(false);
  };

  const showFeedbackModal = () => {
    setFeedBackFormVisibilityState(true);
  };

  const ctaCallEvent = () => {
    const iframeElement = document.querySelector('.ctm-call-widget') as HTMLIFrameElement | null;

    if (iframeElement && iframeElement.contentWindow) {
      iframeElement.contentWindow.postMessage({ action: 'showForm' }, '*');
    }
  };

  return (
    <>
      <GetInTouch
        title={title}
        contactButton={contactButton}
        feedbackForm={feedbackForm}
        bgColor={bgColor}
        isMobileViewWidth={isMobileViewWidth}
        mobileViewMaxWidth={mobileViewMaxWidth}
        closeFeedbackForm={closeFeedbackModal}
        isFeedBackVisible={isFeedBackVisible}
        showFeedbackForm={showFeedbackModal}
        ctaCallEvent={ctaCallEvent}
      />
    </>
  );
};

export default GetInTouchContainer;
