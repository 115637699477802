import { FC } from 'react';

import { Link } from 'src/__generated__/graphqlTypes';

import { ContactButtonsContainerInterface } from './declarations';
import ContactButtons from './ContactButtons';

const ContactButtonsContainer: FC<ContactButtonsContainerInterface> = ({
  block,
  isFeedBackVisible,
  showFeedbackForm,
  ctaCallEvent,
}) => {
  if (!block) {
    return null;
  }

  const { contentTypesCollection } = block;

  const linkBlocks = contentTypesCollection?.items as Array<Link> | undefined || [];

  return (
    <>
      <ContactButtons
        links={linkBlocks}
        isFeedBackVisible={isFeedBackVisible}
        showFeedbackForm={showFeedbackForm}
        ctaCallEvent={ctaCallEvent}

      />
    </>

  );
};

export default ContactButtonsContainer;
