import { FC } from 'react';
import { useSelector } from 'react-redux';

import type { Store } from 'src/redux/types/storeTypes';
import { WebpImage } from 'components/Shared/SharedComponents';
import styles from 'components/Sections/ContactUsGetInTouch/ContactButtons/ButtonImage/ButtonImage.module.scss';
import { ButtonImageInterface } from 'components/Sections/ContactUsGetInTouch/ContactButtons/ButtonImage/declarations';
import { EXTERNAL_WEBP_IMAGE } from 'constants/constants';

const ButtonImage: FC<ButtonImageInterface> = ({
  block,
  showFeedBack,
  ctaCallEvent,
}) => {
  const { title, image } = block;
  const isMobile = useSelector((store: Store) => store.server.app.isMobileViewWidth);

  const getContactNumber = (src?: string | undefined) => {
    if (!src) {
      return null;
    }

    const splitSrc = src.split(':');

    return (splitSrc.length === 2) ? splitSrc[1] : null;
  };

  const contactNumber = typeof block.src === 'string' ? getContactNumber(block.src) : null;

  if (block.src !== '#') {
    return (
      <div className={styles.buttonImage} style={{ background: block.backgroundColor! }}>
        <a className={styles.buttonImageLink} href={block.src ? block.src : undefined}>
          <div className={styles.buttonImageIconContainer}>
            {image && (
              <WebpImage
                image={EXTERNAL_WEBP_IMAGE}
                alt={image.title!}
                src={image.url!}
                className={styles.buttonImageIconContainerIcon}
              />
            )}
          </div>
          <div className={styles.buttonImageTitle} style={{ color: block.color! }}>
            {title}
            {!isMobile && contactNumber && (
              <>
                <br />
                {contactNumber}
              </>
            )}
          </div>
        </a>
      </div>
    );
  }

  let isEmail = false;

  if (block.slug === 'contact-email') {
    isEmail = true;
  }

  return (
    <button
      type="button"
      className={styles.buttonImage}
      style={{ background: block.backgroundColor! }}
      onClick={isEmail ? showFeedBack : ctaCallEvent}
    >
      <div className={styles.buttonImageIconContainer}>
        {image && (
          <WebpImage
            image={EXTERNAL_WEBP_IMAGE}
            alt={image.title!}
            src={image.url!}
            className={styles.buttonImageIconContainerIcon}
          />
        )}
      </div>
      <div className={styles.buttonImageTitle} style={{ color: block.color! }}>{title}</div>
    </button>

  );
};
export default ButtonImage;
